import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import Box from "@vertikal/e-prospera.ui.box";
import Paper from "@vertikal/e-prospera.ui.paper";
import styled from "styled-components";
import triangles from "./../images/community-triangles.svg";

export const CommunityLayout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CommunityHero = styled.div`
  padding-top: 4rem;
  height: 72vh;
  min-height: 550px;
  max-height: 803px;
  position: relative;
  color: ${pureColors.white};
  margin-bottom: -23rem;
  > * {
    position: relative;
  }
  :before {
    content: "";
    background-color: ${colors.accent.c900};
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url(${triangles});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 642px;
    @media ${breakpoints.xs} { 
      height: 59.25%;
    }
    @media ${breakpoints.sm} {
      height: 62.25%;
    }
    @media ${breakpoints.md} {
      height: 68.25%;
      
    }
  }
  @media ${breakpoints.sm} {
    margin-bottom: -21rem;
  }
  @media ${breakpoints.md} {
    margin-bottom: -18rem;
    height: 75vh;
    min-height: 768px;
  }
`;

export const CommunityIntro = styled(Box)`
  max-width: 40rem;
  p {
    margin-top: 2rem;
    color: ${colors.gray.c300};
  }
`;

export const CommunityDiscordIntro = styled(Box)`
  height: 10rem;
  padding: 23rem 1.5rem 2rem 1.5rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;

  background-color: ${colors.success.c200};
  overflow: hidden;
  @media ${breakpoints.sm} {
    padding: 19rem 1.5rem 2rem 1.5rem;
  }
  @media ${breakpoints.md} {
    align-items: center;
    padding: 12rem 0 2rem 0;
    gap: 2rem;
  }
  @media ${breakpoints.lg} {
    padding: 12rem 0 2rem 0;
  }
  @media ${breakpoints.xl} {
    padding: calc(15rem - 5vw) 0 0 0;
  }
  p {
    width: auto;
    max-width: 35.625rem;
    color: ${colors.success.c800};
  }
`;

export const DiscordIcon = styled(Box)`
  background-color: ${colors.success.c400};
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${breakpoints.md} {
    height: 96px;
    width: 96px;
    border-radius: 48px;
  }
  > div {
    width: 50%;
    height: auto;
  }
`;

export const CommunityContent = styled.div`
  padding: 4rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const MaxWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  margin: 0 auto;
  height: 100%;
  padding: 0 1rem;
  @media ${breakpoints.md} {
    padding: 0 2.5rem;
  }
`;

export const CommunityGallery = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) =>
    !!props.$gap
      ? props.$gap
      : '3rem'};
  margin-top: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  @media ${breakpoints.md} {
    gap: ${(props) =>
      !!props.$gap
        ? props.$gap
        : '1.5rem'};
  }
`;

export const CommunityCard = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  max-width: 400px;
  @media ${breakpoints.sm} {
    width: 400px;
    height: 400px;
  }
`;

export const CommunityCTA = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  background-color: ${colors.accent.c900};
  padding: 6rem 1.5rem;
  text-align: center;
  h3, p {
    color: ${pureColors.white}
  }
`;

export const MeetingCard = styled(Paper)`
  width: 100%;
  max-width: 22rem;
  min-height: 10rem;
  text-decoration: none;
  padding: 1.5rem;
`;

export const CTABox = styled(Box)`
  border: 2px solid ${colors.main.c200};
  display: flex;
  flex-align: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  color: ${colors.main.c500};
`;

