import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import Text from "@vertikal/e-prospera.ui.text";
import WidgetBot from "@widgetbot/react-embed";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import GlobalSettings from "../components/GlobalSettings";
import MainMenu from "../components/MainMenu/MainMenu";
import {
  CommunityCard,
  CommunityContent,
  CommunityCTA,
  CommunityDiscordIntro,
  CommunityGallery,
  CommunityHero,
  CommunityIntro,
  CommunityLayout,
  CTABox,
  DiscordIcon,
  MaxWidthWrapper,
  MeetingCard,
} from "../styles/community.styles";

const CommunityPage = () => {
  const [t] = useTranslation();

  const meetingCards = [
    {
      id: "community-meeting-card-residency",
      title: t("common.residency"),
      subtitle: t("public.community.meeting.card1.subtitle"),
      link: 'https://calendar.app.google/Cg1rED5qmignFoJt6',
    },
    {
      id: "community-meeting-card-enttites",
      title: t("common.entities"),
      subtitle: t("public.community.meeting.card2.subtitle"),
      link: 'https://calendar.app.google/d62y4H3TC7FFxtzs6',
    },
    {
      id: "community-meeting-card-taxes",
      title: t("common.taxes"),
      subtitle: t("public.community.meeting.card3.subtitle"),
      link: 'https://calendar.app.google/1HN8NwPN88SVpnG16',
    },
    {
      id: "community-meeting-card-labor-law",
      title: t("public.community.meeting.card4.title"),
      subtitle: t("public.community.meeting.card4.subtitle"),
      link: 'https://calendar.app.google/QvcDG2qDujvWKS7e6',
    },
    {
      id: "community-meeting-card-regulated-industries",
      title: t("public.community.meeting.card5.title"),
      subtitle: t("public.community.meeting.card5.subtitle"),
      link: 'https://calendar.app.google/EAPwjt4psK5GNZ93A',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("public.community.meta.page_title")}</title>,
        <meta name="title" content={t("public.community.meta.meta_title")} />
        <meta
          name="description"
          content={t("public.community.meta.meta_description")}
        />
      </Helmet>
      <GlobalSettings />
      <MainMenu darkFooter>
        <CommunityLayout>
          <CommunityHero>
            <MaxWidthWrapper>
              <CommunityIntro marginBottom={2.5}>
                <Text variant="h3" as="h1">
                  <Trans>public.community.page_title</Trans>
                </Text>
                <Text variant="p">
                  <Trans>public.community.description</Trans>
                </Text>
              </CommunityIntro>
              {typeof window !== "undefined" && (
                <WidgetBot
                  style={{
                    maxHeight: "675px",
                    position: "relative",
                    zIndex: "1",
                  }}
                  width={"100%"}
                  height={"100%"}
                  server="922527213293805609"
                  channel="922527423868862534"
                />
              )}
            </MaxWidthWrapper>
          </CommunityHero>
          <Angles flipHorizontal matchGreen />
          <CommunityDiscordIntro>
            <DiscordIcon>
              <StaticImage
                placeholder="tracedSVG"
                src="../images/illustrations/discord-icon.svg"
                alt="Discord logo"
              />
            </DiscordIcon>
            <Text variant="p">
              <Trans>public.community.discord</Trans>
            </Text>
          </CommunityDiscordIntro>
          <Angles flipVertical matchGreen whiteBase />
          <CommunityContent>
            <MaxWidthWrapper>
              <Box display="flex" flexDirection="column" alignItems="center" marginBottom={6}>
                <Text
                  variant="h3"
                  as="h2"
                  style={{ marginBottom: "1.5rem", textAlign: "center" }}
                >
                  <Trans>public.community.benefits.title</Trans>
                </Text>
                <Text variant="p" style={{ textAlign: "center" }}>
                  <Trans>public.community.benefits.description</Trans>
                </Text>
                <CommunityGallery
                  display="flex"
                  flexDirection="row"
                  gap={1.5}
                  marginTop={3}
                >
                  <CommunityCard>
                    <StaticImage
                      alt="Marketplace logos"
                      src="../images/illustrations/community-island.png"
                    />
                    <Text variant="p">
                      <Trans>public.community.benefits.partners</Trans>
                    </Text>
                  </CommunityCard>
                  <CommunityCard>
                    <StaticImage
                      alt="Marketplace logos"
                      src="../images/illustrations/community-network.png"
                    />
                    <Text variant="p">
                      <Trans>public.community.benefits.support</Trans>
                    </Text>
                  </CommunityCard>
                  <CommunityCard>
                    <StaticImage
                      alt="Marketplace logos"
                      src="../images/illustrations/community-updates.png"
                    />
                    <Text variant="p">
                      <Trans>public.community.benefits.updates</Trans>
                    </Text>
                  </CommunityCard>
                </CommunityGallery>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="center">
                <Box maxWidth={39}>
                  <Text
                    variant="h3"
                    as="h2"
                    style={{ marginBottom: "1.5rem", textAlign: "center" }}
                  >
                    <Trans>public.community.meeting.title</Trans>
                  </Text>
                  <Text variant="p" align="center">
                    <Trans>public.community.meeting.description</Trans>
                  </Text>
                </Box>
                <CommunityGallery $gap="1rem">
                  {meetingCards.map((card, key) => (
                    <MeetingCard
                      href={card.link}
                      target="_blank"
                      elevation={2}
                      interactive
                      key={card.id}
                      id={`community-book-meeting-card-${key}`}
                      borderRadius={5}
                    >
                      <Box padding={1.5} display="flex" flexDirection="column" alignItems="center">
                        <Text variant="h4">{card.title}</Text>
                        <Text variant="p" color="gray">{card.subtitle}</Text>
                        <CTABox>
                          <Text variant="h6">{t("public.community.meeting.cta")}</Text>
                        </CTABox>
                      </Box>
                    </MeetingCard>
                  ))}
                </CommunityGallery>
              </Box>
            </MaxWidthWrapper>
          </CommunityContent>
          <Angles flipHorizontal flipVertical />
          <CommunityCTA>
            <Text variant="h4" as="h3">
              <Trans>community.become_a_part_of_the_conversation</Trans>
            </Text>
            <Text>
              <Trans>community.get_involved</Trans>
            </Text>
            <Button
              href="https://discord.com/invite/nXWh7ytuXB"
              target="_blank"
              rel="noreferrer"
              color="main"
              id="join-the-community"
            >
              <Trans>community.cta</Trans>
            </Button>
          </CommunityCTA>
        </CommunityLayout>
      </MainMenu>
    </>
  );
};

export default CommunityPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
